<template>
  <personalidad-form
    okbtn="Crear informe personalidad"
    title="Crear informe de personalidad"
    :informe_personalidad.sync="data"
    :id_persona_rol_alumno="id_persona_rol_alumno"
    :id_cursos="id_cursos"
    :id_periodo="id_periodo"
    :nombre_modal="nombre_modal"
    :nombre_estudiante.sync="nombre_estudiante"
    :curso.sync="curso"
    @processForm="agregar"
    @resetData="resetData"
  />
</template>

<script>
import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import PersonalidadForm from './PersonalidadForm.vue'

export default {
  components: {
    // COMPONENTES
    PersonalidadForm,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        id_persona_rol_estudiante: null,
        id_periodo: null,
        indicadores: [],
        observacion: '',
      },
    }
  },
  props: {
    nombre_estudiante: {
      type: String,
      required: true,
    },
    id_persona_rol_alumno: {
      type: Number,
      required: true,
    },
    id_cursos: {
      type: Number,
      required: true,
    },
    id_periodo: {
      type: Number,
      required: true,
    },
    nombre_modal: {
      type: String,
      required: true,
    },
    curso: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      fetchInformesPersonalidad: 'informesPersonalidad/fetchInformesPersonalidad',
      createInformePersonalidad: 'informesPersonalidad/addInformePersonalidad',
    }),
    agregar(informe) {
      this.createInformePersonalidad(informe).then(() => {
        const statusPersonalidad = store.state.informesPersonalidad.status
        if (statusPersonalidad === 'success') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Informe de personalidad guardado 👍',
              icon: 'CheckIcon',
              text: 'El informe de personalidad fue creado con éxito!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 4000,
          })
          this.$bvModal.hide(this.nombre_modal)
          const data = {
            id_curso: this.id_cursos,
            id_periodo: this.id_periodo
          }
          this.fetchInformesPersonalidad(data)
          this.resetData()
        }
        else {
          const text = store.state.informesPersonalidad.message
          this.$swal({
            title: 'Faltan conceptos por seleccionar',
            text,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
    resetData() {
      this.data = []
      this.data = {
        id_persona_rol_estudiante: null,
        id_periodo: null,
        indicadores: [],
        observacion: '',
      }
    },
  },
}
</script>
